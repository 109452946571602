import Auth from './auth'

import './middleware'

// Active schemes
import scheme_003d9a64 from './schemes/local.js'
import scheme_5b3692c8 from './schemes/microsoftScheme.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":true,"fullPathRedirect":false,"watchLoggedIn":true,"redirect":{"login":"/login","logout":"/login","home":false,"callback":"/login"},"vuex":{"namespace":"auth"},"cookie":{"prefix":"auth.","options":{"path":"/"}},"localStorage":false,"token":{"prefix":"_token."},"refresh_token":{"prefix":"_refresh_token."},"defaultStrategy":"local"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new scheme_003d9a64($auth, {"endpoints":{"login":{"url":"/auth/login","method":"post","propertyName":"token"},"logout":{"url":"/auth/logout","method":"post"},"user":{"url":"/auth/user","method":"get","propertyName":"user"}},"token":{"property":"token","global":true,"type":"Bearer"},"user":{"property":"user"},"_name":"local"}))

  // microsoft
  $auth.registerStrategy('microsoft', new scheme_5b3692c8($auth, {"msal":{"auth":{"authority":"https://login.microsoftonline.com/organizations","clientId":"9d703942-a6bf-409b-8886-94b9557ad1da","redirectUri":"https://app.smiaware.com/sso/auth"},"cache":{"cacheLocation":"sessionStorage"}},"token":{"property":"token","global":true,"type":"Bearer"},"user":{"property":"user"},"endpoints":{"linkAccounts":{"url":"sso/link-account","method":"post"},"provision":{"url":"sso/provision-user","method":"get"},"user":{"url":"/auth/user","method":"get","propertyName":"user"}},"_name":"microsoft"}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}
