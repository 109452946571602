import { isURL } from 'validator'

export default (ctx, inject) => {
  inject('rules', {
    email: (message = 'Invalid email format', { trim } = { trim: false }) => (value) => {
      if (!value && value !== false && value !== 0) return true

      return /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/
        .test(trim ? value.trim() : value) || message
    },
    doesntContainProtocol: (message = 'URL should not contain protocol, e.g. "https://"') => (value) => {
      return /^(((http(s)?)|((s)?ftp)):\/\/)/.test(value) ? message : true
    },
    maxLength: (max = 0, message = `Field must be less than ${max + 1} characters`) => (value) => {
      return !value || value?.length < max || message
    },
    maxValue: (max = 0, message = `Field must not be greater than ${max}`) => (value) => {
      return value <= max || message
    },
    minLength: (min = 0, message = `Field must be at least ${min} characters`) => (value) => {
      return value?.length >= min || message
    },
    minValue: (min = 0, message = `Field must not be less than ${min}`) => (value) => {
      return value >= min || message
    },
    password: (message = 'The password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character, i.e. !@#$%^*~') => (value) => {
      if (!value && value !== false && value !== 0) return true
      // eslint-disable-next-line
      return /^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\X])(?=.*[!@#$%^*~]).*$/.test(value) || message
    },
    phone: (message = 'Invalid phone number format: (555) 555-5555') => (value) => {
      if (!value && value !== false && value !== 0) return true
      return /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(value) || message
    },
    regex: (regex, message = 'Invalid format') => (value) => {
      if (!value) return true
      return new RegExp(regex).test(value) || message
    },
    required: (message = 'This field is required') => (value) => {
      const test = typeof value === 'string'
        ? value?.trim() !== ''
        : !!value

      return test || message
    },
    requiredIf: (callback, message = 'This field is required') => (value) => {
      const valueTest = typeof value === 'string'
        ? value?.trim() !== ''
        : Array.isArray(value)
          ? value.length > 0
          : !!value

      const test = callback(value)

      return test
        ? valueTest || message
        : true
    },
    sameAs: (matchValue, message = 'Fields must match') => (value) => {
      return value === matchValue || message
    },
    url: (message = 'Invalid URL format: https://www.example.com') => (value) => {
      if (!value) return true
      return isURL(value, { protocols: ['http', 'https', 'ftp', 'ftps', 'sftp'] }) || message
    },
    fileName: (message = 'Invalid characters in filename. Allowed characters include: 0-9 A-Z a-z ! - _ . * \' ()') => (value) => { // eslint-disable-line
      const testValue = typeof 'object' && value instanceof File
        ? value.name
        : value

      // AWS recommends keys use the following characters 0-9 A-Z a-z ! - _ . * ' ()
      // Other characters are permitted but can cause issues e.g. – (EM Dash)

      // eslint-disable-next-line
      return /^[A-Za-z0-9\.!\-_\*'\(\) ]*$/g.test(testValue) || message
    },
    filetype: (types = [], message = 'Invalid filetype') => (value) => {
      return (!value || types.includes(value?.type)) || message
    },
    fileSize: (maxSize, message = 'File is too large') => (value) => {
      return (!value || value?.size <= maxSize || message)
    },
  })
}
