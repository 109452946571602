export default ({ $auth, $axios }) => {
  $axios.onRequest(() => {
    if ($auth.loggedIn && $auth.strategy.name === 'microsoft') {
      $auth.strategy.fetchToken().then((accessTokenResponse) => {
        if (!accessTokenResponse?.accessToken) return
        const token = accessTokenResponse.accessToken
        $auth.strategy.setToken(token)
      })
    }
  })
}
