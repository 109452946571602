import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _321343ca = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _04069cce = () => interopDefault(import('../pages/accounts/index.vue' /* webpackChunkName: "pages/accounts/index" */))
const _5db8d20b = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _3e203b74 = () => interopDefault(import('../pages/deep-reports/index.vue' /* webpackChunkName: "pages/deep-reports/index" */))
const _8bffb6da = () => interopDefault(import('../pages/Error.vue' /* webpackChunkName: "pages/Error" */))
const _dff93ba8 = () => interopDefault(import('../pages/expert-witnesses/index.vue' /* webpackChunkName: "pages/expert-witnesses/index" */))
const _087588ad = () => interopDefault(import('../pages/exports/index.vue' /* webpackChunkName: "pages/exports/index" */))
const _60836f81 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _355dafb0 = () => interopDefault(import('../pages/file-relay/index.vue' /* webpackChunkName: "pages/file-relay/index" */))
const _c7a33b20 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _144643ce = () => interopDefault(import('../pages/invoices/index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _6f20ecd8 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _1357f01c = () => interopDefault(import('../pages/non-invoiced-items/index.vue' /* webpackChunkName: "pages/non-invoiced-items/index" */))
const _92d35f3c = () => interopDefault(import('../pages/projects/index.vue' /* webpackChunkName: "pages/projects/index" */))
const _1ca9ae84 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _58a9b4f6 = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _f578af7e = () => interopDefault(import('../pages/admin/auto-search-blacklist.vue' /* webpackChunkName: "pages/admin/auto-search-blacklist" */))
const _3b786682 = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _e123c196 = () => interopDefault(import('../pages/admin/smi-users.vue' /* webpackChunkName: "pages/admin/smi-users" */))
const _3c11a416 = () => interopDefault(import('../pages/client/dashboard.vue' /* webpackChunkName: "pages/client/dashboard" */))
const _0761b320 = () => interopDefault(import('../pages/customer-service/dashboard.vue' /* webpackChunkName: "pages/customer-service/dashboard" */))
const _2cdc4e78 = () => interopDefault(import('../pages/legacy-orders/exports.vue' /* webpackChunkName: "pages/legacy-orders/exports" */))
const _57bb8744 = () => interopDefault(import('../pages/legacy-orders/reports.vue' /* webpackChunkName: "pages/legacy-orders/reports" */))
const _2c22fd16 = () => interopDefault(import('../pages/new-users/accept-invitation.vue' /* webpackChunkName: "pages/new-users/accept-invitation" */))
const _3937b622 = () => interopDefault(import('../pages/new-users/invite.vue' /* webpackChunkName: "pages/new-users/invite" */))
const _c3297d18 = () => interopDefault(import('../pages/new-users/setup.vue' /* webpackChunkName: "pages/new-users/setup" */))
const _dde433aa = () => interopDefault(import('../pages/new-users/view-invitations.vue' /* webpackChunkName: "pages/new-users/view-invitations" */))
const _5f0fd846 = () => interopDefault(import('../pages/orders/start.vue' /* webpackChunkName: "pages/orders/start" */))
const _7138ac24 = () => interopDefault(import('../pages/policies/privacy.vue' /* webpackChunkName: "pages/policies/privacy" */))
const _60a117a1 = () => interopDefault(import('../pages/policies/terms-of-use.vue' /* webpackChunkName: "pages/policies/terms-of-use" */))
const _3db24166 = () => interopDefault(import('../pages/sso/auth.vue' /* webpackChunkName: "pages/sso/auth" */))
const _d1a0f93a = () => interopDefault(import('../pages/accounts/user/settings.vue' /* webpackChunkName: "pages/accounts/user/settings" */))
const _02703306 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _32917ab6 = () => interopDefault(import('../pages/accounts/_id.vue' /* webpackChunkName: "pages/accounts/_id" */))
const _3e12e45e = () => interopDefault(import('../pages/deep-reports/_deepReportId/index.vue' /* webpackChunkName: "pages/deep-reports/_deepReportId/index" */))
const _c0fc0528 = () => interopDefault(import('../pages/exports/_exportId/index.vue' /* webpackChunkName: "pages/exports/_exportId/index" */))
const _62842fa0 = () => interopDefault(import('../pages/invoices/_invoiceId/index.vue' /* webpackChunkName: "pages/invoices/_invoiceId/index" */))
const _637d1116 = () => interopDefault(import('../pages/deep-reports/_deepReportId/case-notes.vue' /* webpackChunkName: "pages/deep-reports/_deepReportId/case-notes" */))
const _ed77d14a = () => interopDefault(import('../pages/deep-reports/_deepReportId/edit.vue' /* webpackChunkName: "pages/deep-reports/_deepReportId/edit" */))
const _ba98a6d8 = () => interopDefault(import('../pages/deep-reports/_deepReportId/workspace.vue' /* webpackChunkName: "pages/deep-reports/_deepReportId/workspace" */))
const _240ec860 = () => interopDefault(import('../pages/exports/_exportId/edit.vue' /* webpackChunkName: "pages/exports/_exportId/edit" */))
const _4877d6af = () => interopDefault(import('../pages/exports/_exportId/workspace.vue' /* webpackChunkName: "pages/exports/_exportId/workspace" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _321343ca,
    name: "account"
  }, {
    path: "/accounts",
    component: _04069cce,
    name: "accounts"
  }, {
    path: "/contact",
    component: _5db8d20b,
    name: "contact"
  }, {
    path: "/deep-reports",
    component: _3e203b74,
    name: "deep-reports"
  }, {
    path: "/Error",
    component: _8bffb6da,
    name: "Error"
  }, {
    path: "/expert-witnesses",
    component: _dff93ba8,
    name: "expert-witnesses"
  }, {
    path: "/exports",
    component: _087588ad,
    name: "exports"
  }, {
    path: "/faq",
    component: _60836f81,
    name: "faq"
  }, {
    path: "/file-relay",
    component: _355dafb0,
    name: "file-relay"
  }, {
    path: "/forgot-password",
    component: _c7a33b20,
    name: "forgot-password"
  }, {
    path: "/invoices",
    component: _144643ce,
    name: "invoices"
  }, {
    path: "/login",
    component: _6f20ecd8,
    name: "login"
  }, {
    path: "/non-invoiced-items",
    component: _1357f01c,
    name: "non-invoiced-items"
  }, {
    path: "/projects",
    component: _92d35f3c,
    name: "projects"
  }, {
    path: "/reset-password",
    component: _1ca9ae84,
    name: "reset-password"
  }, {
    path: "/users",
    component: _58a9b4f6,
    name: "users"
  }, {
    path: "/admin/auto-search-blacklist",
    component: _f578af7e,
    name: "admin-auto-search-blacklist"
  }, {
    path: "/admin/dashboard",
    component: _3b786682,
    name: "admin-dashboard"
  }, {
    path: "/admin/smi-users",
    component: _e123c196,
    name: "admin-smi-users"
  }, {
    path: "/client/dashboard",
    component: _3c11a416,
    name: "client-dashboard"
  }, {
    path: "/customer-service/dashboard",
    component: _0761b320,
    name: "customer-service-dashboard"
  }, {
    path: "/legacy-orders/exports",
    component: _2cdc4e78,
    name: "legacy-orders-exports"
  }, {
    path: "/legacy-orders/reports",
    component: _57bb8744,
    name: "legacy-orders-reports"
  }, {
    path: "/new-users/accept-invitation",
    component: _2c22fd16,
    name: "new-users-accept-invitation"
  }, {
    path: "/new-users/invite",
    component: _3937b622,
    name: "new-users-invite"
  }, {
    path: "/new-users/setup",
    component: _c3297d18,
    name: "new-users-setup"
  }, {
    path: "/new-users/view-invitations",
    component: _dde433aa,
    name: "new-users-view-invitations"
  }, {
    path: "/orders/start",
    component: _5f0fd846,
    name: "orders-start"
  }, {
    path: "/policies/privacy",
    component: _7138ac24,
    name: "policies-privacy"
  }, {
    path: "/policies/terms-of-use",
    component: _60a117a1,
    name: "policies-terms-of-use"
  }, {
    path: "/sso/auth",
    component: _3db24166,
    name: "sso-auth"
  }, {
    path: "/accounts/user/settings",
    component: _d1a0f93a,
    name: "accounts-user-settings"
  }, {
    path: "/",
    component: _02703306,
    name: "index"
  }, {
    path: "/accounts/:id",
    component: _32917ab6,
    name: "accounts-id"
  }, {
    path: "/deep-reports/:deepReportId",
    component: _3e12e45e,
    name: "deep-reports-deepReportId"
  }, {
    path: "/exports/:exportId",
    component: _c0fc0528,
    name: "exports-exportId"
  }, {
    path: "/invoices/:invoiceId",
    component: _62842fa0,
    name: "invoices-invoiceId"
  }, {
    path: "/deep-reports/:deepReportId?/case-notes",
    component: _637d1116,
    name: "deep-reports-deepReportId-case-notes"
  }, {
    path: "/deep-reports/:deepReportId?/edit",
    component: _ed77d14a,
    name: "deep-reports-deepReportId-edit"
  }, {
    path: "/deep-reports/:deepReportId?/workspace",
    component: _ba98a6d8,
    name: "deep-reports-deepReportId-workspace"
  }, {
    path: "/exports/:exportId/edit",
    component: _240ec860,
    name: "exports-exportId-edit"
  }, {
    path: "/exports/:exportId/workspace",
    component: _4877d6af,
    name: "exports-exportId-workspace"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
