import LocalScheme from '@nuxtjs/auth/lib/schemes/local'
import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser'

export default class MicrosoftScheme extends LocalScheme {
  awaitingPopup = false
  constructor (auth, options) {
    super(auth, options)
    this.$msal = new PublicClientApplication(options.msal)
  }

  async mounted () {
    await this.$msal.initialize()
    return super.mounted()
  }

  async fetchToken () {
    const account = this.$msal.getActiveAccount()
    if (!account) return null

    const accessTokenRequest = {
      account,
      scopes: ['api://9d703942-a6bf-409b-8886-94b9557ad1da/smiaware.app.access'],
    }

    const accessTokenResponse = await this.$msal
      .acquireTokenSilent(accessTokenRequest)
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          if (!this.awaitingPopup) {
            this.awaitingPopup = true
            return this.$msal.acquireTokenPopup(accessTokenRequest)
          }
        }
      })

    this.awaitingPopup = false

    // If a token is not found reset auth
    if (!accessTokenResponse?.accessToken) {
      this.$auth.reset()
    }

    return accessTokenResponse
  }

  #prepToken (accessToken) {
    return this.options.tokenType
      ? this.options.tokenType + ' ' + accessToken
      : accessToken
  }

  setToken (accessToken) {
    if (!this.options.tokenRequired) {
      return
    }

    const token = this.#prepToken(accessToken)

    this.$auth.setToken(this.name, token)

    if (this.options.globalToken) {
      // Set Authorization token for all axios requests
      this.$auth.ctx.app.$axios.setHeader(this.options.tokenName, token)
    }
  }

  // Override `login` method of `local` scheme
  async login (
    endpoint,
    { reset = true } = {}
  ) {
    // Ditch any leftover local tokens before attempting to log in
    if (reset) {
      this.$auth.reset({ resetInterceptor: false })
    }

    // Add client id to payload if defined
    if (this.options.clientId) {
      endpoint.data.client_id = this.options.clientId // eslint-disable-line camelcase
    }

    // Add grant type to payload if defined
    if (this.options.grantType) {
      endpoint.data.grant_type = this.options.grantType // eslint-disable-line camelcase
    }

    // Add scope to payload if defined
    if (this.options.scope) {
      endpoint.data.scope = this.options.scope
    }

    // Make login request
    await this.$msal.loginPopup({})

    const myAccounts = this.$msal.getAllAccounts()
    const account = myAccounts[0]
    this.$msal.setActiveAccount(account)

    const accessTokenResponse = await this.fetchToken()
    const accessToken = accessTokenResponse.accessToken

    if (!accessToken) {
      this.$msal.setActiveAccount(null)
    }

    this.setToken(accessToken)

    const provisionResult = await this.$auth
      .requestWith(this.name, endpoint, this.options.endpoints.provision)
      .catch(() => { })

    if (provisionResult.status !== 'success') {
      this.$auth.reset()
    }

    if (provisionResult.status !== 'success' && provisionResult.status !== 'login-required') {
      this.$msal.setActiveAccount(null)
    }

    if (this.options.autoFetchUser) {
      await this.fetchUser()
    }

    return provisionResult
  }

  async linkAccounts (data) {
    const tokenResponse = await this.fetchToken()
    const token = this.#prepToken(tokenResponse.accessToken)

    this.$auth.setToken(this.name, token)

    await this.$auth
      .requestWith(this.name, { data }, this.options.endpoints.linkAccounts)

    return this.login({})
  }

  // Override `logout` method of `local` scheme
  async logout (endpoint) {
    super.logout(endpoint)
    if (!this.awaitingPopup) {
      this.awaitingPopup = true
      await this.$msal.logoutPopup()
      this.awaitingPopup = false
    }
    this.$msal.setActiveAccount(null)
  }
}
